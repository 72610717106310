import React, { useContext } from 'react';
import { GlobalStateContext } from '../../hooks/globalStateContext';
import { Link as GatsbyLink } from 'gatsby';
import { IContentLink } from '../../types/contentfulContentTypes';
import normalizePathname from '../../utils/normalizePathname';
import * as alert from '../../services/alert.service';

const ProtocolRegEx = new RegExp('^(?:[a-z]+:)?//', 'i');

interface ContentfulLinkProps {
  link?: IContentLink | string;
  [attributes: string]: any;
}
/**
 * This component takes a Contentful Content: Link entry and determines
 * whether it should use an internal GatsbyLink or HTMLAnchorElement.
 */
const ContentfulLink: React.FC<ContentfulLinkProps> = (props) => {
  const { children, link, ...attributes } = props;
  const { microcopy } = useContext(GlobalStateContext);
  if (!link) {
    // broken link
    return (
      <span
        title="This link is broken"
        className="text-red-500"
        {...attributes}
      >
        {children}
      </span>
    );
  } else if (typeof link === 'string') {
    if (link.startsWith('#')) {
      return (
        <a className={'link'} href={link} {...attributes}>
          {children}
        </a>
      );
    }
    const isTelNumber = link.includes('tel:');
    if (ProtocolRegEx.test(link) || isTelNumber) {
      return (
        <a
          className={'link'}
          href={`${link}`}
          target="_blank"
          rel="noopener noreferrer"
          {...attributes}
        >
          {children}
        </a>
      );
    } else {
      return (
        <GatsbyLink
          to={normalizePathname(link)}
          {...attributes}
          {...(typeof window !== 'undefined' && {
            state: {
              prevPath: window.location.pathname,
              prevParams: window.location.search,
            },
          })}
          // state={{ prevPath: window.location.pathname }}
        >
          {children}
        </GatsbyLink>
      );
    }
  } else {
    if (
      link?.externalUrl?.includes('ryobitools.com') ||
      link?.externalUrl?.includes('ryobi.com.au') ||
      link?.externalUrl?.includes('ryobitools.eu') ||
      link?.externalUrl?.includes('ryobitools.mx')
    ) {
      const handleClick = (e) => {
        e.preventDefault();
        alert.show(
          'info',
          <h4>{microcopy?.Note ?? 'Note'}</h4>,
          <p className="mt-0">
            {microcopy?.LeavingCadSite ??
              'You are now leaving the Canadian site.'}
          </p>,
          link.externalUrl,
        );
      };

      return (
        <a onClick={handleClick} href={`${link.externalUrl}`} {...attributes}>
          {children}
        </a>
      );
    } else if (link.externalUrl) {
      const targetProperties = link.openInNewWindow
        ? {
            target: '_blank',
            rel: 'noopener noreferrer',
          }
        : {};
      return (
        <a href={`${link.externalUrl}`} {...targetProperties} {...attributes}>
          {children}
        </a>
      );
    } else if (link.page?.internal?.type === 'ContentfulPageProductsList') {
      const productLineSlug = link?.page?.productLine?.slug;
      const productFamilySlug = link?.page?.productFamily?.slug;
      const categorySlug = link?.page?.category?.slug;
      const subcategorySlug = link?.page?.subcategory?.slug;

      const pathname = [
        productLineSlug,
        'products',
        productFamilySlug,
        categorySlug,
        subcategorySlug,
      ]
        .filter((slug) => slug != null && slug !== '')
        .join('/')
        .toLowerCase();

      return (
        <GatsbyLink to={normalizePathname(pathname ?? '/')} {...attributes}>
          {children}
        </GatsbyLink>
      );
    } else if (link.page != null && link.openInNewWindow === true) {
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={normalizePathname(link.page.slug ?? '/')}
          {...attributes}
        >
          {children}
        </a>
      );
    } else if (link.page != null) {
      return (
        <GatsbyLink
          to={normalizePathname(link.page.slug ?? '/')}
          {...attributes}
        >
          {children}
        </GatsbyLink>
      );
    } else {
      // broken link
      return (
        <span
          title="This link is broken"
          className="text-red-500"
          {...attributes}
        >
          {children}
        </span>
      );
    }
  }
};
export default ContentfulLink;
