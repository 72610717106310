import React, { useEffect, useState } from 'react';

export interface ScrollToTopProps {
  screenReaderText: string;
}

/**
 * This component builds out a a scroll to top button which appears when user scrolls down page.
 * @returns ScrollToTopButton
 */
const ScrollToTopButton: React.FC<ScrollToTopProps> = (props) => {
  const { screenReaderText } = props;
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <>
      {isVisible && (
        <div className="w-10 h-10 bg-black text-ryobi-green justify-center items-center text-center z-50 opacity-100 right-7 bottom-7 rounded-full fixed">
          <button
            className="text-ryobi-green font-bold text-lg scale-x-150 scale-y-75 z-50 p-xxs text-center"
            onClick={scrollToTop}
            type="button"
          >
            ^<span className="sr-only">{screenReaderText}</span>
          </button>
        </div>
      )}
    </>
  );
};
export default ScrollToTopButton;
