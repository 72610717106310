import { INavigationItem } from '../components/ui/Header';
import { IComponentNavigation } from '../types/contentfulContentTypes';

export const getNavigationItem = (
  item: IComponentNavigation,
): INavigationItem => {
  return {
    text: item.text ?? '-- No text provided --',
    link: item.link,
    layout: item.layout,
    image: item.image,
    items: item.items?.map(getNavigationItem),
  };
};

export const mapNavigationItems = (
  items: IComponentNavigation[],
): INavigationItem[] => {
  return items?.map(getNavigationItem);
};
