/* eslint-disable react/display-name */
import React from 'react';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { Document, BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { replaceContentfulLineBreaks } from './replaceContentfulLineBreaks';
import { replaceContentfulHtml } from './replaceContentfulHtml';
import { replaceContentfulNonBreakingSpace } from './replaceContentfulNonBreakingSpace';
import ContentfulImage from '../components/contentful/ContentfulImage';
import ContentfulLink from '../components/contentful/ContentfulLink';
import {
  IContentLink,
  IMediaAsset,
  IContentfulEntryReference,
} from '../types/contentfulContentTypes';

const getDefaultOptions: (
  references: Array<IContentfulEntryReference | IMediaAsset>,
  id: string,
  className: string,
) => Options = (references, id, className) => ({
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // Contentful seems to add empty paragraphs at the end of rich-text
      // cf: https://github.com/contentful/rich-text/issues/101
      const isEmptyChildren = children?.toString()?.trim() === '';
      if (isEmptyChildren) {
        return null;
      }

      if (id)
        return (
          <p id={id} className="scroll-m-20">
            {children}
          </p>
        );

      return <p className={`${className}`}>{children}</p>;
    },
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const asset = references?.find(
        ({ contentful_id }) => contentful_id === node?.data?.target?.sys?.id,
      );
      if (asset) {
        const image = asset as IMediaAsset;
        return (
          <ContentfulImage
            image={image}
            alt={image.description}
            className="mt-5"
          />
        );
      }
      switch (node.data?.target.__typename) {
        case 'ContentfulAsset': {
          const contentfulAsset = node.data?.target as IMediaAsset;
          return (
            <ContentfulImage
              image={contentfulAsset}
              alt={contentfulAsset?.description || ''}
            />
          );
        }
      }
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      return (
        node?.data?.target && (
          <>
            Block embedded entry not support:{' '}
            <pre>{JSON.stringify(node?.data?.target, null, 2)}</pre>
          </>
        )
      );
    },
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      return (
        node?.data?.target && (
          <>
            Inline embedded entry not support:{' '}
            <pre>{JSON.stringify(node?.data?.target, null, 2)}</pre>
          </>
        )
      );
    },
    [INLINES.ASSET_HYPERLINK]: (node) => {
      return (
        <a
          href={`https://${node.data?.target?.file?.url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {(node.content?.[0] as any)?.value}
        </a>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      const link = node?.data?.target as IContentLink;
      const entryRef = references?.find(
        ({ contentful_id }) => contentful_id === link?.sys?.id,
      );
      if (entryRef?.slug) {
        return (
          <ContentfulLink link={entryRef?.slug} className={'link'}>
            {children}
          </ContentfulLink>
        );
      }
      const id = entryRef?.data?.find(
        (d: { key: string; value: string }) => d.key === 'key',
      )?.value;
      if (id) {
        return <ContentfulLink link={`#${id}`}>{children}</ContentfulLink>;
      }
      return <ContentfulLink link={link}>{children}</ContentfulLink>;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const link = node?.data?.target as IContentLink;
      return (
        <ContentfulLink link={node?.data?.uri ?? link}>
          {children}
        </ContentfulLink>
      );
    },
  },
  renderText: (text) => {
    let value: string[] | unknown[] = replaceContentfulLineBreaks(text);
    value = replaceContentfulNonBreakingSpace(value);
    value = replaceContentfulHtml(value);
    return value;
  },
});

/**
 * renderRichText
 * Helper function that renders Contentful rich text fields as React elements.
 *
 * @param rawRichText Raw string Contentful rich-text field - A contentful rich text `raw` rich text field.
 * @param options Optional. Contentful rich-text options object.
 * @return React.ReactNode - The corresponding React elements for the rich text.
 */
export const renderContentfulRichText = (
  rawRichText?: string,
  options?: Options,
  references?: Array<IContentfulEntryReference>,
  id?: string,
  className?: string,
): React.ReactNode => {
  if (!rawRichText) {
    return <></>;
  }
  let richTextField: Document;
  try {
    const json = JSON.parse(rawRichText);
    richTextField = json;
  } catch {
    // can't parse text
    return <></>;
  }
  // return early if no rich text
  if (!richTextField) {
    return <></>;
  }

  const defaultOptions = getDefaultOptions(references, id, className);

  const combinedContentfulOptions = {
    ...defaultOptions,
  };

  // combine default options with passed options
  if (options && options.renderMark) {
    combinedContentfulOptions.renderMark = {
      ...defaultOptions.renderMark,
      ...options.renderMark,
    };
  }
  if (options && options.renderNode) {
    combinedContentfulOptions.renderNode = {
      ...defaultOptions.renderNode,
      ...options.renderNode,
    };
  }
  if (options && options.renderText) {
    combinedContentfulOptions.renderText = options.renderText;
  }

  return (
    <>{documentToReactComponents(richTextField, combinedContentfulOptions)}</>
  );
};
