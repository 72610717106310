import React, { useCallback, useContext } from 'react';
import { Link } from 'gatsby';
import SocialMediaList from '../SocialMediaList';
import ContentfulImage from '../contentful/ContentfulImage';
import ContentfulLink from '../contentful/ContentfulLink';
import { renderContentfulRichText } from '../../utils/renderContentfulRichText';
import {
  IComponentForm,
  IContentFlexible,
  IMediaAsset,
} from '../../types/contentfulContentTypes';
import { INavigationItem } from './Header';
import { GlobalStateContext } from '../../hooks/globalStateContext';
import MailchimpForm from '../forms/MailchimpForm';

export interface FooterProps {
  exclusiveRetailer?: IContentFlexible;
  form?: IComponentForm;
  legal?: IContentFlexible;
  logo?: IMediaAsset;
  menuColumns?: INavigationItem[];
  socials?: INavigationItem[];
}
const SiteFooter: React.FC<FooterProps> = (props) => {
  const { exclusiveRetailer, legal, logo, menuColumns, form, socials } = props;
  const { microcopy } = useContext(GlobalStateContext);

  const exclusiveRetailerImage = exclusiveRetailer?.primaryImage?.[0] && (
    <ContentfulImage
      image={exclusiveRetailer?.primaryImage?.[0]}
      className="w-[60px] h-[60px]"
      alt={microcopy?.HomeDepotImageAltText}
    />
  );

  const renderNavigationItem = useCallback((item: INavigationItem) => {
    return (
      <ContentfulLink link={item.link} className="text-black leading-none">
        {item.text}
      </ContentfulLink>
    );
  }, []);

  const renderMenu = useCallback((menu: INavigationItem) => {
    return (
      <>
        <h4 className="type-label uppercase font-bold mt-md first:mt-0">
          {menu.text}
        </h4>
        <ul className="list-none mt-xxs">
          {menu.items?.map((item, index) => (
            <li key={index}>{renderNavigationItem(item)}</li>
          ))}
        </ul>
      </>
    );
  }, []);

  const renderColumn = React.useCallback((column: INavigationItem) => {
    return (
      <>
        {column.items &&
          column.items.map((menu, index) => {
            return (
              <React.Fragment key={`menu-${column.id}-item-${index}`}>
                {renderMenu(menu)}
              </React.Fragment>
            );
          })}
      </>
    );
  }, []);

  return (
    <>
      <div id="footer" className="bg-ryobi-green">
        <div className="container desktop:max-w-[1400px] py-md">
          <div className="tablet:grid tablet:grid-cols-8 desktop:grid-cols-12 gap-sm">
            <div className="tablet:col-span-4">
              <div className="flex">
                <div className="grow">
                  {logo && (
                    <Link to={`/`}>
                      <ContentfulImage
                        image={logo}
                        className="aspect-2.88/1 max-w-[100px]"
                        alt={microcopy?.RyobiLogoAltText ?? `Ryobi Home`}
                      />
                      <span className="sr-only">
                        {microcopy?.RyobiLogoAltText ?? `Ryobi Home`}
                      </span>
                    </Link>
                  )}
                </div>
                <div className="grow">
                  {socials && <SocialMediaList items={socials} />}
                </div>
              </div>
              <div className="mt-sm px-xxs">
                <h5 className="mt-sm uppercase type-h5 font-normal">
                  {form?.content?.primaryText}
                </h5>
                <p className="mt-xs type-label text-16 leading-normal font-normal">
                  {form?.content?.secondaryText}
                </p>
                <MailchimpForm />
              </div>
            </div>

            {/* MENU COLUMNS */}
            <div className="tablet:col-span-3 tablet:col-start-6 desktop:col-span-7 desktop:col-start-6 grid grid-cols-2 tablet:gap-sm desktop:mt-0 desktop:grid-cols-4">
              {menuColumns &&
                menuColumns.map((column, index) => {
                  return (
                    <div key={`column-${index}`} className="mt-md tablet:mt-0">
                      {renderColumn(column)}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-black text-white py-sm">
        <div className="container tablet:grid tablet:grid-flow-col-dense tablet:grid-cols-8 desktop:grid-cols-12">
          {exclusiveRetailer && (
            <div className="tablet:col-start-6 tablet:col-span-3 desktop:col-start-10 flex justify-center tablet:justify-end">
              <div className="tablet:flex">
                <div className="type-b2 tablet:text-right">
                  {exclusiveRetailer?.primaryText && (
                    <span>{exclusiveRetailer?.primaryText}</span>
                  )}
                  {exclusiveRetailer?.link?.[0] && (
                    <ContentfulLink
                      link={exclusiveRetailer?.link?.[0]}
                      className="text-white tablet:block"
                    >
                      {exclusiveRetailer?.link?.[0]?.text}
                    </ContentfulLink>
                  )}
                </div>
                <div className="flex justify-center mt-xs tablet:mt-0 tablet:ml-xs">
                  {exclusiveRetailer?.link?.[0] ? (
                    <ContentfulLink link={exclusiveRetailer?.link?.[0]}>
                      {exclusiveRetailerImage}
                      <span className="sr-only">
                        {microcopy?.HomeDepotImageAltText ??
                          `Only at The Home Depot`}
                      </span>
                    </ContentfulLink>
                  ) : (
                    exclusiveRetailerImage
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="mt-xs tablet:mt-0 tablet:col-start-1 tablet:col-span-5 text-center type-micro tablet:text-left">
            {legal?.bodyText?.raw &&
              renderContentfulRichText(
                legal?.bodyText?.raw,
                undefined,
                legal?.bodyText?.references,
              )}
          </div>
        </div>
        {/* One Trust Cookie Captor */}
        <div className="optanon-category-C0004" />
      </div>
    </>
  );
};
export default SiteFooter;
