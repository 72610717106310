const theme = {
  breakpoint: {
    // tailwindcss works with mobile-first, so these are the *start*
    // of when they apply
    tablet: 641,
    desktop: 980,
  },
  grid: {
    columns: { 12: 12, 8: 8, 4: 4 },
    gap: 24,
  },
  aspectRatio: {
    square: 1,
    '4/3': '4 / 3',
    '16/9': '16 / 9',
    '2.88/1': '2.88 / 1',
  },
  container: {
    mobile: 320,
    tablet: 641,
    desktop: 980,
  },
  color: {
    'ryobi-green': '#E1E71B',
    'accent-green': '#B7CD4C',
    'accent-blue': '#50B0F4',
    black: '#000000',
    white: '#ffffff',
    'grey-0': '#f1f1f1',
    'grey-1': '#ececec',
    'grey-2': '#dddddd',
    'grey-3': '#666666',
    'grey-4': '#222222',
  },
  font: {
    family: '"futura-pt", "Helvetica Neue", Helvetica, Arial, sans-serif',
    size: {
      10: 10,
      14: 14,
      16: 16,
      18: 18,
      20: 20,
      24: 24,
      28: 28,
      32: 32,
      36: 36,
      48: 48,
      64: 64,
    },
    weight: {
      light: 300,
      book: 400,
      medium: 500,
      heavy: 600,
    },
    lineHeight: {
      auto: 'auto',
      tight: 1,
      snug: 1.25,
      normal: 1.5,
    },
  },
  spacing: {
    xxs: 8,
    xs: 16,
    sm: 24,
    md: 32,
    lg: 48,
    xl: 64,
    xxl: 90,
  },
};
theme.typography = {
  desktop: {
    h1: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.heavy};
    font-size: ${theme.font.size[64]}px;
    line-height: ${theme.font.lineHeight['1']};`,
    h2: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[48]}px;
    line-height: ${theme.font.lineHeight['1.25']};`,
    h3: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[36]}px;
    line-height: ${theme.font.lineHeight['1.25']};`,
    h4: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[32]}px;
    line-height: ${theme.font.lineHeight['1.25']};`,
    h5: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[24]}px;
    line-height: ${theme.font.lineHeight['1.25']};`,
    b1: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.book};
    font-size: ${theme.font.size[16]}px;
    line-height: ${theme.font.lineHeight['1.5']};`,
    'b1-heavy': `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.heavy};
    font-size: ${theme.font.size[16]}px;
    line-height: ${theme.font.lineHeight['1.25']};`,
    b2: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.book};
    font-size: ${theme.font.size[18]}px;
    line-height: ${theme.font.lineHeight['1.5']};`,
    'hero-button': `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[20]}px;
    line-height: ${theme.font.lineHeight['1.5']};`,
    'default-button': `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[16]}px;
    line-height: ${theme.font.lineHeight['1.5']};`,
    label: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.light};
    font-size: ${theme.font.size[14]}px;
    line-height: ${theme.font.lineHeight['1.25']};`,
    micro: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[10]}px;
    line-height: ${theme.font.lineHeight['1.5']};`,
  },
  tablet: {
    h1: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.heavy};
    font-size: ${theme.font.size[48]}px;
    line-height: ${theme.font.lineHeight['1']};`,
    h2: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[32]}px;
    line-height: ${theme.font.lineHeight['1.25']};`,
    h3: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[28]}px;
    line-height: ${theme.font.lineHeight['1.25']};`,
    h4: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[24]}px;
    line-height: ${theme.font.lineHeight['1.25']};`,
    h5: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[20]}px;
    line-height: ${theme.font.lineHeight['1.25']};`,
    b1: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.book};
    font-size: ${theme.font.size[16]}px;
    line-height: ${theme.font.lineHeight['1.5']};`,
    'b1-heavy': `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.heavy};
    font-size: ${theme.font.size[16]}px;
    line-height: ${theme.font.lineHeight['1.25']};`,
    b2: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.book};
    font-size: ${theme.font.size[18]}px;
    line-height: ${theme.font.lineHeight['1.5']};`,
    'hero-button': `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[20]}px;
    line-height: ${theme.font.lineHeight['1.5']};`,
    'default-button': `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[16]}px;
    line-height: ${theme.font.lineHeight['1.5']};`,
    label: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.light};
    font-size: ${theme.font.size[14]}px;
    line-height: ${theme.font.lineHeight['1.25']};`,
    micro: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[10]}px;
    line-height: ${theme.font.lineHeight['1.5']};`,
  },
  mobile: {
    h1: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.heavy};
    font-size: ${theme.font.size[32]}px;
    line-height: ${theme.font.lineHeight['1']};`,
    h2: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[32]}px;
    line-height: ${theme.font.lineHeight['1.25']};`,
    h3: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[28]}px;
    line-height: ${theme.font.lineHeight['1.25']};`,
    h4: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[24]}px;
    line-height: ${theme.font.lineHeight['1.25']};`,
    h5: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[20]}px;
    line-height: ${theme.font.lineHeight['1.25']};`,
    b1: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.book};
    font-size: ${theme.font.size[16]}px;
    line-height: ${theme.font.lineHeight['1.5']};`,
    'b1-heavy': `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.heavy};
    font-size: ${theme.font.size[16]}px;
    line-height: ${theme.font.lineHeight['1.25']};`,
    b2: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.book};
    font-size: ${theme.font.size[18]}px;
    line-height: ${theme.font.lineHeight['1.5']};`,
    'hero-button': `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[20]}px;
    line-height: ${theme.font.lineHeight['1.5']};`,
    'default-button': `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[16]}px;
    line-height: ${theme.font.lineHeight['1.5']};`,
    label: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.light};
    font-size: ${theme.font.size[14]}px;
    line-height: ${theme.font.lineHeight['1.25']};`,
    micro: `
    font-family: ${theme.font.family};
    font-weight: ${theme.font.weight.medium};
    font-size: ${theme.font.size[10]}px;
    line-height: ${theme.font.lineHeight['1.5']};`,
  },
};

// defined for convenience if needed outside of TailwindCSS
theme.responsive = {
  mobile: {
    breakpoint: 0,
    columns: theme.grid[4],
    container: theme.container.mobile,
  },
  tablet: {
    breakpoint: theme.breakpoint.tablet,
    columns: theme.grid[8],
    container: theme.container.tablet,
  },
  desktop: {
    breakpoint: theme.breakpoint.desktop,
    columns: theme.grid[12],
    container: theme.container.desktop,
  },
};

module.exports = theme;
