import React, { useContext, useEffect, useRef, useState } from 'react';
import { IContentLink, IMediaAsset } from '../../types/contentfulContentTypes';
import SvgArrowDownIcon from '../../assets/svgs/arrow-down-24x24.svg';
import SvgChevronLeftIcon from '../../assets/svgs/chevron-left-24x24.svg';
import SvgChevronRightIcon from '../../assets/svgs/chevron-right-24x24.svg';
import SvgMenuIcon from '../../assets/svgs/menu.svg';
import SvgCloseIcon from '../../assets/svgs/close-32x32.svg';
import SvgHomeIcon from '../../assets/svgs/home-32x32.svg';
import ContentfulImage from '../contentful/ContentfulImage';
import ContentfulLink from '../contentful/ContentfulLink';
import { GlobalStateContext } from '../../hooks/globalStateContext';
import './Header.module.css';

const SCROLL_Y_LIMIT = 150;

export interface INavigationItem {
  text?: string;
  image?: IMediaAsset | string;
  link?: IContentLink | string;
  items?: INavigationItem[];
  layout?: 'Default' | 'Heading';
  [properties: string]: any;
}
export interface ISearchCta {
  text?: string;
  image?: IMediaAsset | string | null;
}
interface HeaderProps {
  logo?: IMediaAsset;
  mainNavigation?: INavigationItem[];
  searchButton?: React.ReactNode;
  userNavigation?: INavigationItem[];
}
const Header: React.FC<HeaderProps> = (props) => {
  const { logo, mainNavigation, searchButton, userNavigation } = props;
  const { microcopy } = useContext(GlobalStateContext);
  const mainNavigationRef = useRef(null);
  const [isMenuShrunk, setIsMenuShrunk] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState<{
    [key: string]: boolean;
  }>({});
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [currentMenuParent, setCurrentMenuParent] = useState<string>('');

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= SCROLL_Y_LIMIT && !isMenuShrunk) {
        setIsMenuShrunk(true);
      } else if (window.scrollY < SCROLL_Y_LIMIT && isMenuShrunk) {
        setIsMenuShrunk(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMenuShrunk]);

  const UserNavigationIconBar = (
    <ul className={`flex flex-1 justify-end desktop:flex-none`}>
      {userNavigation?.map((item, index) => {
        return (
          <li
            key={`user-navigation-icon-item-${index}`}
            className="tablet:ml-sm first:ml-xxs flex items-center"
          >
            {item.image && item.link && (
              <ContentfulLink
                link={item.link}
                className={`flex justify-center items-center`}
              >
                <ContentfulImage
                  image={item.image}
                  className="w-[28px]"
                  alt={item.text}
                />
                {item.text && <span className="sr-only">{item.text}</span>}
              </ContentfulLink>
            )}
          </li>
        );
      })}
    </ul>
  );

  const onClickDesktopNavigationLink = () => {
    if (mainNavigationRef.current != null) {
      // we need to force the main navigation to hide via CSS
      // in order to close any open menus due to the open/close state
      // being based on :hover pseudo classes and TailwindCSS
      mainNavigationRef.current.classList.add('hide-menus');
      setTimeout(() => {
        mainNavigationRef.current.classList.remove('hide-menus');
      }, 1500);
    }
  };

  const renderDropDownItem = (item: INavigationItem) => {
    if (item.layout === 'Heading') {
      return (
        <span className="block py-xxs px-md type-micro text-ryobi-green uppercase font-bold whitespace-nowrap">
          {item.text}
        </span>
      );
    } else {
      return (
        <ContentfulLink
          link={item.link}
          onClick={onClickDesktopNavigationLink}
          className="block py-xxs px-md type-default-button text-white uppercase whitespace-nowrap"
        >
          {item.text}
        </ContentfulLink>
      );
    }
  };

  const renderDropDownMenu = (items: INavigationItem[], level = 0) => {
    if (level === 0) {
      return (
        <div
          className={`drop-down-menu absolute min-w-[200px] max-h-[90vh] top-full shadow-xl bg-grey-4 py-sm -right-lg -left-xs invisible group-primary-hover:visible opacity-0 group-primary-hover:opacity-100 transition-ease duration-200 ease-in`}
        >
          {items.map((item, index) => (
            <div
              key={`drop-down-menu-level-${level}-item-${index}`}
              className="group-secondary"
            >
              {renderDropDownItem(item)}
              {item.items &&
                item.items.length > 0 &&
                renderDropDownMenu(item.items, level + 1)}
            </div>
          ))}
        </div>
      );
    } else if (level === 1) {
      return (
        <div
          className={`drop-down-menu absolute top-0 left-full min-w-[200px] max-h-[90vh] shadow-xl bg-grey-4 py-sm invisible group-secondary-hover:visible opacity-0 group-secondary-hover:opacity-100 transition-ease duration-200 ease-in`}
        >
          {items.map((item, index) => (
            <div
              key={`drop-down-menu-level-${level}-item-${index}`}
              className="group-tertiary"
            >
              {renderDropDownItem(item)}
              {item.items &&
                item.items.length > 0 &&
                renderDropDownMenu(item.items, level + 1)}
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div
          className={`drop-down-menu absolute top-0 left-full min-w-[200px] max-h-[90vh] shadow-xl bg-grey-4 py-sm invisible group-tertiary-hover:visible opacity-0 group-tertiary-hover:opacity-100 transition-ease duration-200 ease-in`}
        >
          {items.map((item, index) => (
            <div key={`drop-down-menu-level-${level}-item-${index}`}>
              {renderDropDownItem(item)}
              {item.items &&
                item.items.length > 0 &&
                renderDropDownMenu(item.items, level + 1)}
            </div>
          ))}
        </div>
      );
    }
  };

  const handleClickOpenMobileSubMenuButton = (key: string) => {
    setIsSubMenuOpen((state) => {
      const newState = { ...state };
      newState[key] = true;
      setCurrentMenuParent(key);
      return newState;
    });
    setBodyClass(true);
  };

  const handleClickMobileSubMenuBackButton = (key: string) => {
    setIsSubMenuOpen((state) => {
      const newState = { ...state };
      newState[key] = false;
      setCurrentMenuParent('');
      return newState;
    });
  };

  const handleClickMobileLink = () => {
    setIsMobileMenuOpen(false);
    setBodyClass(false);
  };

  //stop body from scrolling behind mobile menu
  const setBodyClass = (isMobileMenuOpen: boolean) => {
    const body = document.querySelector('body');
    if (isMobileMenuOpen) {
      body.classList.add('overflow-hidden');
    } else {
      body.classList.remove('overflow-hidden');
    }
  };

  const renderMobileSubItem = (item: INavigationItem) => {
    if (item.layout === 'Heading') {
      return (
        <span className="block type-micro text-ryobi-green uppercase font-bold">
          {item.text}
        </span>
      );
    } else {
      return (
        <ContentfulLink
          link={item.link}
          onClick={handleClickMobileLink}
          className="flex items-center h-full type-default-button uppercase text-white"
        >
          {item.text}
        </ContentfulLink>
      );
    }
  };

  const renderBackButton = (key: string) => (
    <button
      onClick={() => handleClickMobileSubMenuBackButton(key)}
      className="flex items-center px-sm h-[48px] w-full border-solid border-0 border-b border-black/[.4] text-white"
    >
      <SvgChevronLeftIcon
        className="group-primary mr-xxs"
        fill="white"
        height="16px"
        width="16px"
      />
      <span className="uppercase type-default-button">
        {microcopy?.Back ?? 'Back'}
      </span>
    </button>
  );

  const renderMobileSubMenu = (
    items: INavigationItem[],
    key: string,
    level = 0,
  ) => {
    return (
      <div
        className={`absolute flex flex-col z-10 top-0 left-0 w-full !h-full bg-grey-4 text-white transition-transform ${
          isSubMenuOpen[key] ? `translate-x-0` : 'translate-x-full'
        }`}
      >
        {renderBackButton(key)}
        <ul className="overflow-auto flex-1">
          {items.map((item, index) => {
            const subMenuKey = `${key}-sub-menu-${index}`;
            return (
              <li
                key={subMenuKey}
                className={`flex items-center px-sm overflow-auto`}
              >
                {item.items && item.items.length > 0 ? (
                  <button
                    onClick={() =>
                      handleClickOpenMobileSubMenuButton(subMenuKey)
                    }
                    className={`flex items-center w-full h-[45px] ${
                      subMenuKey !== currentMenuParent ? `visible` : `invisible`
                    }`}
                  >
                    {renderMobileSubItem(item)}
                    <SvgChevronRightIcon
                      className="ml-auto group-primary"
                      fill="white"
                      height="16px"
                      width="16px"
                    />
                  </button>
                ) : (
                  <div className="flex items-center h-[45px]">
                    {renderMobileSubItem(item)}
                  </div>
                )}
                {item.items &&
                  item.items.length > 0 &&
                  renderMobileSubMenu(item.items, subMenuKey, level + 1)}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const handleClickMobileMenuButton = () => {
    if (!isMobileMenuOpen) {
      // reset submenus
      setIsSubMenuOpen({});
    }

    setIsMobileMenuOpen(!isMobileMenuOpen);
    setBodyClass(!isMobileMenuOpen);
  };

  return (
    <>
      <header
        className={`sticky z-20 top-[-64px] desktop:top-[-48px]`}
        id="ryobi-header"
      >
        <div className="bg-black h-[64px] desktop:h-[48px]">
          <div className="container flex desktop:hidden justify-center h-full">
            {logo && (
              <ContentfulLink
                link="/"
                className="flex justify-center items-center py-xxs"
              >
                <ContentfulImage
                  image={logo}
                  alt={microcopy?.RyobiLogoAltText ?? `Ryobi Canada - Home`}
                  className="block h-[32px] desktop:h-[48px]"
                />
                <span className="sr-only">
                  {microcopy?.RyobiLogoAltText ?? `Ryobi Canada - Home`}
                </span>
              </ContentfulLink>
            )}
          </div>
          <div className="hidden desktop:flex container justify-end items-center h-full">
            <div className="flex">
              {userNavigation?.map((item, index) => {
                return (
                  <ContentfulLink
                    key={`user-navigation-link-${index}`}
                    className={`ml-sm first:ml-0 flex items-center justify-center uppercase type-b1 text-ryobi-green`}
                    link={item.link}
                  >
                    {item.image && (
                      <ContentfulImage
                        image={item.image}
                        alt=""
                        className={`mr-xxs w-[24px] ${
                          (index + 1) % 3 === 0 && 'scale-x-[1.2] scale-y-[1.5]'
                        }`}
                      />
                    )}
                    {item.text}
                  </ContentfulLink>
                );
              })}
            </div>
          </div>
        </div>
        <nav ref={mainNavigationRef}>
          <div className="flex w-full top-0 mx-auto desktop:h-[60px] h-[50px] bg-ryobi-green">
            <div className="mx-xxs tablet:container flex flex-1 items-center h-full">
              <div className="grid grid-cols-3 w-full desktop:hidden">
                <div className="">
                  <button
                    aria-label={
                      microcopy?.ToggleMobileMenuText ?? 'Toggle Menu'
                    }
                    onClick={handleClickMobileMenuButton}
                    className="flex justify-center items-center py-xxs pr-md"
                  >
                    <SvgMenuIcon height="28" width="28" />
                    <span className="sr-only">
                      {microcopy?.ToggleMobileMenuText ?? 'Toggle Menu'}
                    </span>
                  </button>
                </div>
                <div className="flex justify-center">{searchButton}</div>
                <div className="flex justify-start">
                  {UserNavigationIconBar}
                </div>
              </div>

              <div className="hidden desktop:flex w-full">
                <div className="flex items-center">
                  {logo && (
                    <ContentfulLink link="/" className="flex items-center">
                      <ContentfulImage
                        image={logo}
                        alt={microcopy?.RyobiLogoAltText ?? `Ryobi Home`}
                        className="h-[32px] desktop:h-[48px] transition-all duration-250 ease-in"
                      />
                    </ContentfulLink>
                  )}
                </div>
                <div className="flex-1">
                  <ul className="list-none flex items-center h-full">
                    {mainNavigation?.map((item, index) => {
                      return (
                        <li
                          key={`main-navigation-item-level-${index}`}
                          className="relative flex items-center cursor-pointer px-sm py-xs group-primary"
                        >
                          <span className="type-default-button uppercase inline-flex items-center">
                            {item.text}
                            {item.items && item.items.length > 0 && (
                              <SvgArrowDownIcon
                                className="ml-xxs"
                                fill="black"
                                height="16px"
                                width="16px"
                                alt=""
                              />
                            )}
                          </span>
                          {item.items &&
                            item.items.length > 0 &&
                            renderDropDownMenu(item.items)}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div
                  className={`${
                    isMenuShrunk ? `is-shrunk` : ``
                  } ml-auto flex justify-end items-center`}
                >
                  {searchButton}
                  <div className={`${isMenuShrunk ? `block` : `hidden`} ml-xs`}>
                    {UserNavigationIconBar}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div
        className={`desktop:hidden bg-grey-4/[.975] fixed z-30 top-0 left-0 w-full h-screen overflow-hidden transition-transform ${
          isMobileMenuOpen ? '' : '-translate-x-full'
        }`}
      >
        <div className="flex flex-col h-full">
          <div className="flex justify-between items-center h-[48px] px-sm border-solid border-0 border-b border-black/[.4]">
            <ContentfulLink
              onClick={handleClickMobileLink}
              link="/"
              className="flex"
            >
              <SvgHomeIcon
                width="28"
                height="28"
                fill="white"
                alt={microcopy?.RyobiLogoAltText ?? `RYOBI Home`}
              />
              <span className="sr-only">
                {microcopy?.RyobiLogoAltText ?? `RYOBI Home`}
              </span>
            </ContentfulLink>
            <button onClick={handleClickMobileMenuButton}>
              <SvgCloseIcon
                width="28"
                height="28"
                fill="white"
                alt={microcopy?.MobileMenuButtonCloseText ?? `Close menu`}
              />
              <span className="sr-only">
                {microcopy?.MobileMenuButtonCloseText ?? `Close menu`}
              </span>
            </button>
          </div>

          <ul className="flex-1 relative z-10 flex flex-col items-center list-none">
            {mainNavigation?.map((item, index) => {
              const hasItems = item.items && item.items.length > 0;
              const subMenuKey = `mobile-menu-${index}`;
              return (
                <li
                  key={subMenuKey}
                  className="flex items-center w-full h-[45px] px-sm cursor-pointer"
                >
                  <button
                    onClick={() =>
                      handleClickOpenMobileSubMenuButton(subMenuKey)
                    }
                    className="flex items-center w-full h-full"
                    aria-label={
                      microcopy?.MobileMenuOpenSubMenuText ?? `Open submenu`
                    }
                  >
                    {renderMobileSubItem(item)}
                    {hasItems && (
                      <div className="flex-1 h-full flex justify-end items-center group-primary">
                        <SvgChevronRightIcon
                          className=""
                          fill="white"
                          height="16px"
                          width="16px"
                          alt=""
                        />
                      </div>
                    )}
                  </button>
                  {hasItems &&
                    renderMobileSubMenu(item.items ?? [], subMenuKey)}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
export default Header;
