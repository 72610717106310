import React, { useContext } from 'react';
import { getImage } from 'gatsby-plugin-image';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { GlobalStateContext } from '../../hooks/globalStateContext';
import { IMediaAsset } from '../../types/contentfulContentTypes';

interface SEOProps {
  card?: string;
  children?: React.ReactNode;
  description?: string;
  image?: IMediaAsset;
  language?: string;
  locale?: string;
  noindex?: boolean;
  ogTitle?: string;
  title?: string;
  type?: string;
}

const SEO: React.FC<SEOProps> = (props) => {
  const {
    description,
    image,
    language,
    locale,
    noindex = false,
    ogTitle,
    title,
    type,
  } = props;

  const { page, metadata } = useContext(GlobalStateContext);

  // build canonical url by ensuring we use the siteUrl and pathname without trailing slash
  const baseHref = `${metadata?.siteUrl}`;

  const pathname =
    page!.location?.pathname?.length > 1 &&
    page!.location?.pathname?.endsWith('/')
      ? page!.location?.pathname.substring(
          0,
          page!.location?.pathname?.length - 1,
        )
      : page!.location?.pathname;

  const canonicalUrl = `${baseHref}${pathname}`;

  // build hreflang alternatives
  const languageAlternates = [
    { hrefLang: 'en-CA', href: 'https://www.ryobitools.ca/' },
    { hrefLang: 'fr-FR', href: 'https://fr.ryobitools.ca/' },
  ];

  // convert locale
  const htmlLangCode = locale?.toLowerCase();

  // create images for social sharing
  const socialShareImages = [];

  if (image != null && image.gatsbyImageData != null) {
    const gatsbyImageSrc = getImage(image.gatsbyImageData);
    if (gatsbyImageSrc) {
      socialShareImages.push({
        url: gatsbyImageSrc.images?.fallback?.src as string,
        width: gatsbyImageSrc.width,
        height: gatsbyImageSrc.height,
        alt: metadata?.siteName,
      });
    }
  }

  return (
    <GatsbySeo
      title={title}
      description={description}
      canonical={canonicalUrl}
      language={language}
      languageAlternates={languageAlternates}
      noIndex={noindex}
      htmlAttributes={{
        language: htmlLangCode,
        // ^ The above does not add the lang attribute but has been kept for documentation purposes in case the property is updated, the lang type error below can be ignored.
        lang: htmlLangCode,
      }}
      openGraph={{
        title: ogTitle,
        description,
        type: type,
        url: canonicalUrl,
        site_name: metadata?.siteName,
        images: socialShareImages,
      }}
    />
  );
};
export default SEO;
