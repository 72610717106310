import React from 'react';
import { Script } from 'gatsby';

export const OneTrust: React.FC = () => {
  const id = '1133c4da-1cd7-4168-b1ce-e54623281267-test';
  if (!id) return null;
  return (
    <>
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-document-language="true"
        type="text/javascript"
        data-domain-script={id}
      ></Script>
      <Script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: 'function OptanonWrapper() {}',
        }}
      ></Script>
    </>
  );
};
