import React, {
  FormEvent,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import Modal from 'react-modal';
import { navigate } from 'gatsby';
import { IMediaAsset } from '../../types/contentfulContentTypes';
import ContentfulImage from '../contentful/ContentfulImage';
import SvgSearchIcon from '../../assets/svgs/search-32x32.svg';
import SvgCloseIcon from '../../assets/svgs/close-32x32.svg';
import ContentfulLink from '../contentful/ContentfulLink';
import { GlobalStateContext } from '../../hooks/globalStateContext';

export interface ISearchCta {
  text?: string;
  image?: IMediaAsset | string | null;
}

interface SearchButtonProps {
  appRoot?: string | HTMLElement;
  button?: ISearchCta;
  logo?: IMediaAsset | string;
  placeholderText?: string;
}

const SearchButton: React.FC<SearchButtonProps> = (props) => {
  const {
    appRoot = '#___gatsby',
    button,
    logo,
    placeholderText = 'Search for tools, videos, etc...',
  } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const formRef = useRef<HTMLFormElement>(null);
  const { microcopy } = useContext(GlobalStateContext);

  Modal.setAppElement(appRoot);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onSubmitSearchForm = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (searchQuery) {
        navigate(`/search?q=${searchQuery}`);
        setIsModalOpen(false);
      }
    },
    [searchQuery],
  );

  return (
    <>
      {button && (
        <>
          <button
            id="searchButton"
            className="flex items-center type-default-button leading-none justify-end outline-none"
            onClick={openModal}
          >
            {button.image && (
              <ContentfulImage
                image={button.image}
                className={`max-h-[32px]`}
                width={32}
                height={32}
                alt=""
              />
            )}
            <span className={`search-btn-text ml-xxs uppercase text-black`}>
              {button.text}
            </span>
          </button>

          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel={placeholderText}
            aria={{
              labelledby: 'heading',
              describedby: 'description',
            }}
            style={{
              content: {
                top: '0',
                left: '0',
                width: '100%',
                padding: '0',
                backgroundColor: 'transparent',
                border: '0',
              },
              overlay: {
                background:
                  'linear-gradient(rgba(241, 241, 241, 0.95), rgba(230, 230, 230, 0.985))',
                zIndex: '50',
              },
            }}
          >
            <h1 id="heading" className={`sr-only`}>
              {placeholderText}
            </h1>
            <div
              className={`container mt-xs desktop:mt-lg desktop:py-[5px] flex flex-col desktop:flex-row items-center justify-center`}
            >
              {logo && (
                <div className="flex items-center justify-center mb-xs desktop:mb-0 desktop:mr-sm">
                  <ContentfulLink
                    link="/"
                    className="flex justify-center items-center"
                  >
                    <ContentfulImage
                      image={logo}
                      className="h-[32px] desktop:h-[48px]"
                      alt={microcopy?.RyobiLogoAltText ?? `Ryobi Canada - Home`}
                    />
                    <span className="sr-only">
                      {microcopy?.RyobiLogoAltText ?? `Ryobi Canada - Home`}
                    </span>
                  </ContentfulLink>
                </div>
              )}
              <form
                onSubmit={onSubmitSearchForm}
                ref={formRef}
                className={`flex-1 w-full relative focus-within:border-accent-blue`}
              >
                <div
                  className={`desktop:origin-[right_center] ${
                    isModalOpen ? `animate-slide-in-right` : ``
                  }`}
                >
                  <button
                    type="submit"
                    className="absolute inset-y-0 left-0 flex items-center px-4 hover:bg-accent-blue border-solid border-transparent border-r border-r-grey-0 z-20 animate-fade-in"
                    aria-label={microcopy?.SubmitSearchText ?? `Submit search`}
                  >
                    <SvgSearchIcon
                      className="w-[18px] h-[18px] desktop:w-[32px] desktop:h-[32px] fill-current hover:!text-white"
                      alt=""
                    />
                  </button>
                  <label htmlFor="search-input" className="sr-only">
                    <span>{placeholderText}</span>
                  </label>
                  <input
                    id="search-input"
                    autoFocus
                    onChange={(e) => {
                      if (e.target.value) {
                        setSearchQuery(e.target.value);
                      }
                    }}
                    placeholder={placeholderText}
                    type="text"
                    name="search"
                    className={`block w-full py-xs desktop:py-sm pl-[84px] pr-16 border border-accent-blue border-solid rounded-none focus:outline-none focus:border-accent-blue focus:ring-accent-blue focus:ring-1 desktop:placeholder:text-2xl`}
                  />
                  <button
                    onClick={closeModal}
                    className="absolute inset-y-0 right-4 flex items-center"
                  >
                    <SvgCloseIcon className="w-[18px] h-[18px] desktop:w-[32px] desktop:h-[32px] fill-grey-3 hover:fill-black" />
                  </button>
                </div>
              </form>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default SearchButton;
