import React, { useContext } from 'react';
import { GlobalStateContext } from '../../hooks/globalStateContext';

export interface SkipToContentProps {
  linkText: string;
}

/**
 * This component builds out a skip to content link.
 * @props Skip link text
 * @returns SkipToContentLink
 */
const SkipToContentLink: React.FC<SkipToContentProps> = (props) => {
  const { linkText } = props;

  return (
    <a
      href="#content"
      className="sr-only focus:not-sr-only text-ryobi-green bg-grey-4 text-center uppercase focus:p-sm focus:absolute focus:inset-center focus:top-12 focus:left-1/2  focus:-translate-x-1/2 focus:-translate-y-1/2 outline-none focus:ring-2 focus:ring-ryobi-green focus:z-50"
    >
      {linkText}
    </a>
  );
};
export default SkipToContentLink;
