import React from 'react';
import { Microcopy } from '../types/microcopy';
import { WindowLocation } from '@reach/router';
import { PageLayoutProps } from '../components/ui/Layout';
import { PageContext } from '../types/pageContext';

export interface GlobalPageState {
  path: string;
  uri: string;
  location: WindowLocation<WindowLocation['state']>;
  params: Record<string, string>;
  data: PageLayoutProps;
  pageContext: PageContext;
}

export interface SiteMetadata {
  title: string;
  siteUrl: string;
  description: string;
  siteName: string;
  siteCreator: string;
}

export interface GlobalState {
  page?: GlobalPageState;
  metadata?: SiteMetadata;
  microcopy?: Microcopy;
}

export const GlobalStateContext = React.createContext<GlobalState>({});

export const GlobalStateProvider = ({ children, value }: any) => {
  return (
    <GlobalStateContext.Provider
      value={{
        ...value,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};
