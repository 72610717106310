exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-flexible-tsx": () => import("./../../../src/templates/Flexible.tsx" /* webpackChunkName: "component---src-templates-flexible-tsx" */),
  "component---src-templates-product-line-tsx": () => import("./../../../src/templates/ProductLine.tsx" /* webpackChunkName: "component---src-templates-product-line-tsx" */),
  "component---src-templates-product-lists-tsx": () => import("./../../../src/templates/ProductLists.tsx" /* webpackChunkName: "component---src-templates-product-lists-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/Product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-support-tsx": () => import("./../../../src/templates/Support.tsx" /* webpackChunkName: "component---src-templates-support-tsx" */)
}

