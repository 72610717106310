import React from 'react';

/** Replaces and new lines (Shift+Enter) in Contentful rich-text with <br> html elements.
 * Contentful's renderRichText function will not do this, and instead will display the text
 * all on one line.
 *
 * See https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer
 * Section near bottom of Readme, "The renderText callback is a function..." explains this.
 *
 * Modified with https://newbedev.com/how-to-insert-a-new-element-in-between-all-elements-of-a-js-array
 */
export const replaceContentfulLineBreaks = (text: string) =>
  text
    ?.replace(/\u2028/g, '')
    ?.split('\n')
    ?.map((e, i, arr) =>
      i < arr.length - 1 ? [e, <br key={`break-${i}`} />] : [e],
    )
    ?.reduce((a, b) => a.concat(b));
