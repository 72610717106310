import { PageProps } from 'gatsby';
import React, { useContext } from 'react';
import { PageContext } from '../../types/pageContext';
import {
  GlobalStateContext,
  SiteMetadata,
} from '../../hooks/globalStateContext';
import Footer from './Footer';
import Header, { INavigationItem, ISearchCta } from './Header';
import Seo from './Seo';
import {
  IComponentSiteFooter,
  IComponentSiteHeader,
  IGlobalOptions,
  IPageSeo,
} from '../../types/contentfulContentTypes';
import SkipToContentLink from './SkipToContentLink';
import { mapNavigationItems } from '../../services/contentful.service';
import ScrollToTopButton from './ScrollToTopButton';
import SearchButton from './SearchButton';
import { Helmet } from 'react-helmet';
import { OneTrust } from './OneTrust';

export interface PageLayoutProps {
  contentfulPageFlexible?: IPageSeo;
  contentfulPageSupport?: IPageSeo;
  contentfulContentProduct?: IPageSeo;
  contentfulPageProductLine?: IPageSeo;
  contentfulPageProductsList?: IPageSeo;
  contentfulGlobalOptions: IGlobalOptions;
  contentfulComponentSiteHeader: IComponentSiteHeader;
  contentfulComponentSiteFooter: IComponentSiteFooter;
  site: SiteMetadata;
  one_trust_key: string;
}

/**
 * Layout component used by pages as a convenience to avoid always specifying
 * the Seo, Header and Footer components. This component is used to wrap every
 * page via the gatsby-browser.tsx and gatsby-ssr.tsx wrapPageElement hook.
 *
 * cf. https://www.gatsbyjs.com/docs/how-to/routing/layout-components/#how-to-prevent-layout-components-from-unmounting
 */
const Layout: React.FC<PageProps<PageLayoutProps, PageContext>> = (props) => {
  const { children, data, pageContext, path } = props;
  const locale = pageContext.node_locale;
  let pageSeo = data?.contentfulPageFlexible || data?.contentfulPageProductLine;
  const image = pageSeo?.shareImage;

  if (!pageSeo && data?.contentfulContentProduct) {
    pageSeo = {
      pageTitle: `${data.contentfulContentProduct.name} | RYOBI Tools`,
    };
  }

  if (!pageSeo && data?.contentfulPageProductsList?.category?.name) {
    pageSeo = {
      pageTitle: `${data.contentfulPageProductsList.category?.name} | RYOBI Tools`,
    };
  }

  if (!pageSeo && data?.contentfulPageSupport?.pageTitle) {
    pageSeo = {
      pageTitle: `${data?.contentfulPageSupport?.pageTitle} | RYOBI Tools`,
    };
  }

  if (!pageSeo && path === '/search/') {
    const params = new URLSearchParams(window?.location?.search);
    const qParam = params?.get('q');

    pageSeo = {
      pageTitle: `Search results for ${qParam} | RYOBI Tools`,
    };
  }

  const { metadata, microcopy } = useContext(GlobalStateContext);

  // map header
  const mainNavigation: INavigationItem[] = mapNavigationItems(
    data?.contentfulComponentSiteHeader?.mainNavigation ?? [],
  );
  const userNavigation: INavigationItem[] = mapNavigationItems(
    data?.contentfulComponentSiteHeader?.userNavigation ?? [],
  );
  const logo = data?.contentfulComponentSiteHeader?.logo?.[0];
  const search: ISearchCta = {
    text: data?.contentfulComponentSiteHeader?.searchCta?.text,
    image: data?.contentfulComponentSiteHeader?.searchCta?.icon,
  };

  // map footer
  const menus = mapNavigationItems(
    data?.contentfulComponentSiteFooter?.menus ?? [],
  );
  const socials = mapNavigationItems(
    data?.contentfulComponentSiteFooter?.socials ?? [],
  );
  const exclusiveRetailer =
    data?.contentfulComponentSiteFooter?.exclusiveToRetailer;
  const legalCopy = data?.contentfulComponentSiteFooter?.legalCopy;
  const footerLogo = data?.contentfulComponentSiteFooter?.logo?.[0];
  const signupForm = data?.contentfulComponentSiteFooter?.signUpForm;
  const bodyClass = pageSeo?.slug === '/' ? `home` : pageSeo?.slug;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        bodyAttributes={{
          class: `page-${bodyClass ?? `generic`}`,
        }}
      />
      <OneTrust />
      <Seo
        // we set the fields as undefined so that empty string meta tags are not
        // created in <head> -- omitting the meta tag will allow Google or other
        // crawlers make a best guess rather than assuming we purposely put an
        // empty string
        title={pageSeo?.pageTitle || metadata?.title || undefined}
        description={
          pageSeo?.pageDescription?.pageDescription ||
          metadata?.description ||
          undefined
        }
        language={locale || undefined}
        locale={locale || undefined}
        image={image || undefined}
        ogTitle={pageSeo?.pageTitle || metadata?.description || undefined}
        noindex={pageSeo?.seoIndex || undefined}
        type={`website`}
      />
      <SkipToContentLink
        linkText={microcopy?.SkipToContent ?? 'Skip to content'}
      />
      <Header
        logo={logo}
        mainNavigation={mainNavigation}
        searchButton={
          <SearchButton
            button={search}
            logo={logo}
            placeholderText={microcopy?.SearchPlaceholderText}
          />
        }
        userNavigation={userNavigation}
      />
      <main
        id="content"
        className={`relative z-10 w-full max-w-[2560px] mx-auto`}
      >
        {children}
      </main>
      <Footer
        exclusiveRetailer={exclusiveRetailer}
        legal={legalCopy}
        logo={footerLogo}
        menuColumns={menus}
        form={signupForm}
        socials={socials}
      />
      <ScrollToTopButton
        screenReaderText={microcopy?.ScrollToTop ?? 'Scroll to top'}
      />
    </>
  );
};
export default Layout;
