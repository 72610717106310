import React from 'react';
import ContentfulImage from './contentful/ContentfulImage';
import ContentfulLink from './contentful/ContentfulLink';
import { INavigationItem } from './ui/Header';

interface SocialMediaListProps {
  items: INavigationItem[];
}
/**
 * This component builds out a simple list of images with optional links.
 * @param props An array of items with a link and image property
 * @returns SocialMediaList
 */
const SocialMediaList: React.FC<SocialMediaListProps> = (props) => {
  const { items } = props;
  return (
    <div className="flex justify-end">
      {items?.map((item, index) => {
        return (
          <ContentfulLink
            key={`social-media-list-item-${index}`}
            link={item.link}
            className="w-10"
          >
            <ContentfulImage
              image={item.image}
              className="max-w-[30px]"
              alt={item?.link?.text ?? ``}
            />
            <span className="sr-only">{item?.link?.text ?? ``}</span>
          </ContentfulLink>
        );
      })}
    </div>
  );
};
export default SocialMediaList;
