import './src/styles/global.css';
import * as React from 'react';
import Layout from './src/components/ui/Layout';
import {
  SiteMetadata,
  GlobalStateProvider,
} from './src/hooks/globalStateContext';
export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it

  // set page global state
  const page = {
    path: props.path,
    uri: props.uri,
    location: props.location,
    params: props.params,
    data: props.data,
    pageContext: props.pageContext,
  };

  // set microcopy global state
  const microcopy = props?.data?.contentfulGlobalOptions?.microcopy.reduce(
    (acc, entry) => {
      const { key, value } = entry;
      return { ...acc, [key]: value };
    },
    {},
  );

  // set metadata global state
  const metadata = props?.data?.site?.siteMetadata as SiteMetadata;

  return (
    <GlobalStateProvider
      value={{
        page,
        microcopy,
        metadata,
      }}
    >
      <Layout {...props}>{element}</Layout>
    </GlobalStateProvider>
  );
};
