import React, { useContext } from 'react';
import { GlobalStateContext } from '../../hooks/globalStateContext';
import * as alert from '../../services/alert.service';

const MailchimpForm = () => {
  const { microcopy } = useContext(GlobalStateContext);

  const handleSubmit = (event: any) => {
    alert.show(
      'success',
      <h4 className="mt-0">
        {microcopy?.SignUpThankYou ?? 'Thank you for signing up.'}
      </h4>,
      <>
        <p className="mt-0">
          {microcopy?.SignUpSuccess ??
            'You have successfully subscribed to our mailing list.'}
        </p>
      </>,
    );

    setTimeout(() => {
      (
        document.getElementById('mc-embedded-subscribe-form') as HTMLFormElement
      ).reset();
    }, 1000);
  };

  return (
    <div id="mc_embed_signup" className="relative text-sm container p-0">
      <form
        action="https://ryobitools.us13.list-manage.com/subscribe/post-json?u=f70fc9fca9f27334f0221662b&amp;id=499eed1365&amp;f_id=0098c2e1f0"
        method="post"
        target="hiddenFrame"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate mt-sm"
        onSubmit={handleSubmit}
        noValidate={false}
      >
        <div id="mc_embed_signup_scroll">
          <div className="mc-field-group">
            <label htmlFor="mce-MMERGE6" className="sr-only">
              Select your country
            </label>
            <select
              id="mce-MMERGE6"
              name="MMERGE6"
              style={{
                borderColor: '#bdd157',
                borderRadius: 1,
                paddingLeft: 10,
                width: '100%',
                color: 'black',
              }}
              className={`w-full`}
            >
              <option disabled>Select Country</option>
              <option value="Canada">Canada</option>
              <option value="United States">United States</option>
            </select>
          </div>
          <div className="mc-field-group mt-xs">
            <label htmlFor="mce-EMAIL" className="sr-only">
              Email address
            </label>
            <input
              type="email"
              name="EMAIL"
              placeholder="Email Address"
              className={`required w-full`}
              style={{
                borderColor: '#bdd157',
                borderRadius: 1,
                paddingLeft: 5,
              }}
              id="mce-EMAIL"
              required
            />
          </div>
          <div
            style={{ position: 'absolute', left: '-5000px' }}
            aria-hidden="true"
          >
            <label
              htmlFor="b_f70fc9fca9f27334f0221662b_499eed1365"
              className="sr-only"
            >
              Name
            </label>
            <input
              type="text"
              name="b_f70fc9fca9f27334f0221662b_499eed1365"
              id="b_f70fc9fca9f27334f0221662b_499eed1365"
              tabIndex={-1}
              defaultValue=""
            />
          </div>
          <div className="mt-xs">
            <input
              type="submit"
              value="SUBMIT"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="button text-white hover:!text-ryobi-green bg-black w-full h-[42px] rounded-md cursor-pointer"
            />
          </div>
          <iframe
            name="hiddenFrame"
            src="about:blank"
            style={{ display: 'none' }}
          ></iframe>
        </div>
      </form>
    </div>
  );
};

export default MailchimpForm;
