import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const theme = require('../themes/theme');

const MySwal = withReactContent(Swal);

export const show = (
  type: 'success' | 'error' | 'info',
  title: JSX.Element,
  html?: JSX.Element,
  externalUrl?: string,
) => {
  switch (type) {
    case 'success': {
      MySwal.fire({
        icon: 'success',
        title: title,
        html: html,
        confirmButtonColor: theme.color['accent-blue'],
      });
      break;
    }
    case 'error': {
      MySwal.fire({
        icon: 'error',
        title: title,
        html: html,
        confirmButtonColor: theme.color['accent-blue'],
      });
      break;
    }
    case 'info': {
      MySwal.fire({
        icon: 'info',
        title: title,
        html: html,
        confirmButtonColor: theme.color['accent-blue'],
      }).then((result) => {
        if (result.isConfirmed) {
          window.open(externalUrl, '_blank', 'noopener,noreferrer');
        }
      });
      break;
    }
  }
};
